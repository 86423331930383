import $ from 'jquery';
import * as whintegration from '@mod-system/js/wh/integration';

/**
 * Website javascript
 */
var website = {
	/**
	 * Website javascript activeren
	 */
	init: function () {
		website.bg_hover();
		website.owl_slider();
		website.responsive_menu();
		website.scroll_to_content();
		website.change_menu_image();
		website.google_map();
		website.image_hover();
		website.search();
		website.animations();
		website.youtube_video();
		website.calculate_height();
		website.sticky_menu();
		website.YTPlayer();
		website.calculate_width();
		website.calculate_costs();

		/*window.onload = function() {

		};*/
	},
	bg_hover: function () {
		var og_style = '';

		// BOF: team over
		$('#team .item .image-member').on('mouseover', function () {
			og_style = $(this).attr('style');

			if ($(this).data('hover').length) {
				$(this).attr('style', 'background-image: url("' + $(this).data('hover') + '");');
			}
		});

		$('#team .item .image-member').on('mouseout', function () {
			$(this).attr('style', og_style);
		});
		// EOF: team hover
	},
	owl_slider: function() {
		var owl = $('#owl-partners');

		owl.owlCarousel({
			items: 4,
			margin: 10,
			loop: true,
			nav: false,
			dots: false,
			autoplay: true,
			autoplayTimeout: 0,
			autoplayHoverPause: false,
			responsive: {
				0: {
					items: 2
				},
				480: {
					items: 4
				},
				768: {
					items: 5
				},
				992: {
					items: 6
				},
				1200: {
					items: 7
				},
				1500: {
					items: 8
				}
			}
		});
	},
	responsive_menu: function () {
		// Dark overlay
		var overlay = $('#dark-overlay');

		// Get window width
		var width = $(window).width();

		$('.dropdown-menu').each(function () {
			$(this).find('.menu-img .img-holder').css('height', $(this).height());
		});

		$(window).resize(function() {
			width = $(window).width();

			$('.dropdown-menu').each(function () {
				$(this).find('.menu-img .img-holder').css('height', $(this).height());
			});
		});

		$('.navbar-nav > li:not(.dropdown-hover)').hover(function() {
			hide_menu();
		});

		$(".dropdown-hover").mouseenter(function() {
			if (width > 768) {
				// Remove active class
				$('.dropdown-hover').removeClass('open');

				if (!$(this).hasClass('open')) {
					$(this).addClass('open');

					// Add dark overlay
					overlay.css('display', 'block');

					// Add dark class to body
					$('body').addClass('dark');

					$(this).children().closest('.mega-dropdown-menu').addClass('visible');
				}
			}
		});

		var timer;

		// On submenu mouseout close the menu after 0.1s
		$('.mega-dropdown-menu > .menu-content').mouseleave(function () {
			timer = setTimeout(hide_menu, 100);
		}).mouseenter(function () {
			clearTimeout(timer);
		});

		// On menu mouseout close the menu after 0.5s
		$('.navbar-nav').mouseleave(function () {
			timer = setTimeout(hide_menu, 500);
		}).mouseenter(function () {
			clearTimeout(timer);
		});

		$(document).on('click', '.mega-dropdown', function (e) {
			e.stopPropagation();
		});

		function hide_menu() {
			if (width > 768) {
				// Remove open class from the main item
				$('li.mega-dropdown.open').removeClass('open');

				// Remove dark overlay
				overlay.css('display', 'none');

				// Remove dark class from body
				$('body').removeClass('dark');

				// Remove visible class from the sub menu
				$('li.mega-dropdown.open > ul.dropdown-menu').removeClass('visible');
			}
		}

		$('.dropdown-toggle, .navbar-toggle').click(function() {
			reset_arrows();
		});

		// Toggle sub menu chevron icons
		$('.dropdown-toggle').click(function () {
			if ($(this).parent().hasClass('open'))
			{
				$(this).parent().find('i').first().removeClass('fa-chevron-up').addClass('fa-chevron-down');
			}
			else
			{
				$(this).parent().find('i').first().removeClass('fa-chevron-down').addClass('fa-chevron-up');
			}
		});

		function reset_arrows() {
			$('.dropdown-hover').find('i.fa-chevron-up').removeClass('fa-chevron-up').addClass('fa-chevron-down');
		}
	},
	scroll_to_content: function() {
		$('a[href^="#"]').bind('click.smoothscroll', function (e) {
			e.preventDefault();

			var target = this.hash, $target = $(target);

			$('html, body').stop().animate({
				'scrollTop': $target.offset().top
			}, 900, 'swing', function () {
				window.location.hash = target;
			});
		});
	},
	change_menu_image: function() {
		var original_image = null, current_image = null;

		$(".menu-content li").on({
			mouseenter: function () {
				original_image = $(this).parents('.mega-dropdown-menu').find('.img-holder').css('background-image');

				current_image = $(this).parents('.mega-dropdown-menu').find('.img-holder');

				var image = $(this).data('image');

				if (image) {
					current_image.attr('style', 'background-image: url("' + image + '"); height: ' + $(this).parents('.mega-dropdown-menu').height() + 'px');

					// current_image.css('background-image', 'url("' + image + '");');
				}
			},
			mouseleave: function () {
				current_image.css('background-image', original_image);
			}
		});
	},
	google_map: function() {
		google.maps.event.addDomListener(window, 'load', init);

		function init() {
			// Basic options for a simple Google Map
			// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
			var mapOptions = {
				// How zoomed in you want the map to start at (always required)
				zoom: 13,
				scrollwheel: false,
				// The latitude and longitude to center the map (always required)
				center: new google.maps.LatLng(52.285054, 6.777494)
			};

			// Get the HTML DOM element that will contain your map
			// We are using a div with id="map" seen below in the <body>
			var mapElement = document.getElementById('map');

			if (mapElement) {
				// Create the Google Map using our element and options defined above
				var map = new google.maps.Map(mapElement, mapOptions);

				var infoWindow = new google.maps.InfoWindow({
					content: '<strong>Blast</strong><br />\n\
								<br />\n\
								Demmersweg 43-13<br />\n\
								7556 BN Hengelo<br />'
				});

				// Let's also add a marker while we're at it
				var marker = new google.maps.Marker({
					position: new google.maps.LatLng(52.285054, 6.777494),
					map: map,
					title: 'Blast Media'
				});

				google.maps.event.addListener(marker, 'click', function () {
					infoWindow.open(map, marker);
				});
			}
		}
	},
	image_hover: function() {
		var main_image = $('#main-image');

		/*$('ol.dots li').hover(function () {
			// Image src url vervangen
			main_image.attr('src', $(this).data('url'));

			$('ol.dots li').removeClass('active');

			$(this).addClass('active');
		});

		$('ol.dots li[data-order=1]').addClass('active');*/

		// main_image.attr('src', $('ol.dots li[data-order=1]').data('url'));

		$('.images .img').hover(function () {
			var order = $(this).data('order');

			// Image src url vervangen
			main_image.attr('src', $(this).data('url'));

			$('ol.dots li').removeClass('active');

			$('ol.dots li[data-order=' + order + ']').addClass('active');

			$(this).addClass('active');
		});

		$('ol.dots li[data-order=1]').addClass('active');

		main_image.attr('src', $('.images .img[data-order=1]').data('url'));
	},
	search: function() {
		/*var input = $('form#search input[name=s]');

		input.on('keyup', function() {
			$.ajax({
				data: {
					search_string: input.val()
				},
				method: "POST",
				url: $('base').prop('href') + "search/results"
			}).done(function (data) {
				console.log(data);
			});
		});*/

		$('#search-btn').on('mouseover', function() {
			$('#search input[name=q]').focus();
		});
	},
	animations: function() {
		//window and animation items
		var animation_elements = $.find('.animation-element');

		var web_window = $(window);

		var script_executed = 0;

		//check to see if any animation containers are currently in view
		function check_if_in_view() {
			//get current window information
			var window_height = web_window.height();
			var window_top_position = web_window.scrollTop();
			var window_bottom_position = (window_top_position + window_height);

			//iterate through elements to see if its in view
			$.each(animation_elements, function () {

				//get the element sinformation
				var element = $(this);
				var element_height = $(element).outerHeight();
				var element_top_position = $(element).offset().top;
				var element_bottom_position = (element_top_position + element_height);

				//check to see if this current container is visible (its viewable if it exists between the viewable space of the viewport)
				if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
					if (!script_executed) {
						element.addClass('in-view');

						script_executed = 1;
					}
					else {
						element.addClass('static');
					}
				} else {
					element.removeClass('in-view');
				}
			});
		}

		// on or scroll, detect elements in view
		$(window).on('scroll resize', function () {
			check_if_in_view();
		});

		//trigger our scroll event on initial load
		$(window).trigger('scroll');
	},
	calculate_height: function() {
		var width = $('header:not(.dark)').width();

		// 16:9 verhouding toepassen op de header
		$('header:not(.dark), .header-media').css('height', (width *9/16));
	},
	sticky_menu: function() {
		var nav = $('header');
		var lang = $('#lang');
		var top = nav.height() -45;

		$(window).scroll(function () {
			if ($(window).scrollTop() >= top) {
				nav.addClass('dark background').removeClass('light');
				lang.addClass('dark').removeClass('light');

				nav.find('.logo-brand img').attr('src', whintegration.config.imgroot + 'blast-dark.png');
			} else {
				nav.removeClass('background');

				if (nav.height() > 0) {
					nav.addClass('light').removeClass('dark');
					lang.addClass('light').removeClass('dark');

					nav.find('.logo-brand img').attr('src', whintegration.config.imgroot + 'blast-light.png');
				}
				else {
					nav.addClass('dark').removeClass('light');
					lang.addClass('dark').removeClass('light');

					nav.find('.logo-brand img').attr('src', whintegration.config.imgroot + 'blast-dark.png');
				}
			}
		});
	},
	YTPlayer: function() {
		if ($('.videoSection').length) {
			if (($().YTPlayer)) {
				$('.videoSection').YTPlayer();
			}
		}
	},
	youtube_video: function() {
		$('#close-youtube').on('click', function() {
			var url = $('#youtube-video').attr('src');

			$('#youtube-video').attr('src', '');
			$('#youtube-video').attr('src', url);

			$('#youtube-video').attr('src', $('#youtube-video').attr('src').replace('?autoplay=1', ''));
		});

		$('.autoplay').on('click', function (e) {
			$('#youtube-video')[0].src += '?autoplay=1';

			e.preventDefault();
		});
	},
	calculate_width: function() {
		if (!($('body').hasClass('products'))) {
			return false;
		}

		calculate_width();

		function debouncer(func, timeout) {
			var timeout_id, timeout = timeout || 200;

			return function () {
				var scope = this, args = arguments;

				clearTimeout(timeout_id);

				timeout_id = setTimeout(function () {
					func.apply(scope, Array.prototype.slice.call(args));
				}, timeout);
			};
		}

		$(window).resize(debouncer(function (e) {
			calculate_width();
		}));

		function calculate_width() {
			var image = $('#main-image');

			 $('.bg-left').width(image.width());
		}
	},
	calculate_costs: function() {
		if (!$('body').hasClass('calculator')) {
			return false;
		}

		$("#quantity").keydown(function (e) {
			// Allow: backspace, delete, tab, escape, enter and .
			if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
			// Allow: Ctrl+A, Command+A
					(e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
					// Allow: home, end, left, right, down, up
							(e.keyCode >= 35 && e.keyCode <= 40)) {
				// let it happen, don't do anything
				return;
			}
			// Ensure that it is a number and stop the keypress
			if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
				e.preventDefault();
			}
		});

		// Data attributes updaten
		function set_data(element, attribute, value) {
			element.attr('data-' + attribute, value);

			element.data(attribute, value);
		}

		function calculate_digital_price() {
			var row_total = $('.calculator.digital').find('.total-value').find('.price'), total = 0;

			$('.calculator.digital').find('.price').each(function () {
				if (parseInt($(this).data('ignore')) === 1) {
					return true;
				}

				total += parseFloat($(this).data('price'));
			});

			// Totaal afronden op 2 decimalen
			total = total.toFixed(2);

			// Data attribute updaten
			set_data(row_total, 'price', total);

			// Tekstueel updaten
			row_total.text(total.replace('.', ','));
		}

		/**
		 * Sum van het klassieke stoepbord berekenen
		 *
		 * @returns {undefined}
		 */
		function calculate_classic_price() {
			var row_total = $('.calculator.classic').find('.total-value').find('.price'), total = 0;

			$('.calculator.classic').find('.price').each(function () {
				if (parseInt($(this).data('ignore')) === 1) {
					return true;
				}

				total += parseFloat($(this).data('price'));
			});

			// Totaal afronden op 2 decimalen
			total = total.toFixed(2);

			// Data attribute updaten
			set_data(row_total, 'price', total);

			// Tekstueel updaten
			row_total.text(total.replace('.', ','));
		}

		/**
		 * Prijs van een poster berekenen/updaten
		 *
		 * @returns {undefined}
		 */
		function calculate_poster_price() {
			// Prijs van de geselecteerde poster
			var price = $('.calculator.classic .options .calculator-row.active').data('price');

			// Prijs kolom t.b.v. weergave
			var price_row = $('.calculator.classic .options-value').find('.price');

			// Data attribute updaten
			set_data(price_row, 'price', price);

			// Tekstuele weergave updaten
			price_row.text(price.replace('.', ','));

			calculate_classic_price();
		}

		/**
		 * Wisselingen per maand berekenen/updaten
		 *
		 * @returns {undefined}
		 */
		function calculate_changes_per_month() {
			var calculator = $('.calculator.classic');

			// Rows
			var row_costs_per_change = calculator.find('#costs-per-change').find('.price');
			var row_costs_per_month = calculator.find('#costs-per-month').find('.price');

			// Aantal wisselingen per maand ophalen
			var changes_per_month = calculator.find('#quantity').val();

			// Kosten per wisseling per maand berekenen
			var costs_per_change_per_month = (parseInt(changes_per_month) * parseFloat(row_costs_per_change.data('price'))).toFixed(2);

			// Data attribute updaten
			set_data(row_costs_per_month, 'price', costs_per_change_per_month);

			// Tekstuele weergave updaten
			row_costs_per_month.text(costs_per_change_per_month.replace('.', ','));

			calculate_classic_price();
		}

		$('.calculator.classic .options .calculator-row').on('click', function() {
			// Acive class bij vorige elementen weghalen
			$('.calculator.classic .options .calculator-row').removeClass('active');

			// Active class toevoegen
			$(this).addClass('active');

			// Nieuwe poster prijs updaten
			calculate_poster_price();
		});

		$(document).on('change', '.calculator.classic #quantity', function() {
			if (parseInt($(this).val()) === 0) {
				$(this).val(1);
			}

			// Prijs wisselingen per maand updaten
			calculate_changes_per_month();
		});

		// Poster prijs berekenen bij init
		calculate_poster_price();

		// Wisselingen per maand berekenen bij init
		calculate_changes_per_month();

		// Kosten digitaal stoepbord berekenen bij init
		calculate_digital_price();

		$(document).on('submit', '#form-calculator', function(e) {
			e.preventDefault();

			var form = $(this);

			// E-maildares ophalen
			var email = form.find('input[type="email"]').val();

			// Loading gif weergeven
			form.find('#loading').show();

			// E-mailadres veld legen
			$('#form-calculator').find('input[type="email"]').val('');

			html2canvas($('.calculator-wrapper'), {
				onrendered: function (canvas) {
					var image = canvas.toDataURL('image/png;base64');

					// Openen in nieuw tabblad
					// window.open(image, '_blank');

					// Image data (base-64 string)
					$('#calculator-image').val(image);
				}
			});

			setTimeout(function() {
				var calculator_image = $('#calculator-image').val();

				// Kosten ophalen
				var classic_price_per_month = $('.calculator.classic').find('.total-value').find('.price').data('price');
				var digital_price_per_month = $('.calculator.digital').find('.total-value').find('.price').data('price');

				// Wisselingen per maand
				var changes_per_month = $('.calculator.classic').find('#quantity').val();

				// A0 opties keuze selectie
				var option_value_id = $('.calculator.classic').find('.options').find('.calculator-row.active').data('value-id');

				if (parseInt(classic_price_per_month) <= 0 || parseInt(digital_price_per_month) <= 0 || !email.length) {
					swal(form.data('error-title'), form.data('error-message'), 'error');

					alert(parseInt(digital_price_per_month));
				}
				else {
					$.ajax({
						type: 'POST',
						url: $('base').prop('href') + 'calculator/send_result',
						data: {
							classic_price_per_month: classic_price_per_month,
							digital_price_per_month: digital_price_per_month,
							changes_per_month: changes_per_month,
							option_value_id: option_value_id,
							calculator_image: calculator_image,
							email: email
						},
						dataType: 'html'
					}).done(function (data) {
						// Loading gif verbergen
						form.find('#loading').hide();

						// Succes melding weergeven
						swal(form.data('success-title'), form.data('success-message'), 'success');
					})
					.fail(function (jqXHR, status) {
						// Fout opgetreden, toon nette melding
						swal(form.data('error-title'), form.data('error-message'), 'error');
					});
				}
			}, 3000);
		});
	}
};

$.fn.suga = function(options) {
  var settings = $.extend({
      'transitionSpeed': 3000,
      'snap': false
  }, options);

  var $this = $(this);

  // add plugin specific classes
  $this.addClass('suga-slider-wrap');
  $this.children('ul').addClass('suga-slider-group');
  $this.find('li').addClass('suga-slide');

  // caching $$$
  var $slide = $('.suga-slide'),
			$firstEl = $('.suga-slide:first'),
      $group = $('.suga-slider-group'),
      $wrap = $('.suga-slider-wrap');

  var slideWidth = $slide.outerWidth(),
  		slideHeight = $('.suga-slide').height(),
      slideCount = $slide.length,
      totalWidth = slideWidth * slideCount;

  // set width on group element
  $group.width(totalWidth);
  $wrap.height(slideHeight);
  $wrap.wrap('<div class="suga-container"></div>');

  // add first class at start
  if (!$group.find($firstEl).hasClass("suga-first")) {
    $group.find($firstEl).addClass("suga-first");
  }

  // lets move baby
  var transitionSnap = function() {
    var $firstEl = $group.find('.suga-first').html();

    $group.find('.suga-first').animate({
      'margin-left': '-' + slideWidth + 'px'
    }, function(){
     $group.append('<li class="suga-slide">' + $firstEl + '</li>');
     $(this).remove();
     $group.find('li:first').addClass("suga-first");

    });
  };

  var transitionScroll = function() {
     var $firstEl = $group.find('.suga-first').html();

    $group.find('.suga-first').animate({
      'margin-left': '-' + slideWidth + 'px'
    }, settings.transitionSpeed, 'linear', function(){
     $group.append('<li class="suga-slide">' + $firstEl + '</li>');
     $(this).remove();
     $group.find('li:first').addClass("suga-first");
     transitionScroll();
    });
  };

  if (settings.snap) {
    window.setInterval(transitionSnap, settings.transitionSpeed);
  } else {
    transitionScroll();
  }
}

$(window).load(function(){
  $('#logos').suga({
    //'transitionSpeed': 2000,
    //'snap': false
  });
});

const grid = document.querySelector('.grid-filter');

var $grid = new Isotope(grid, {
  itemSelector: ".element-item",
  layoutMode: "fitRows",
});

$('#filters .row').on( 'click', 'button', function() {
	$('#noItems').hide();
  var filterValue = $( this ).attr('data-filter');

	$grid = new Isotope(grid, { filter: filterValue });

	if(!JSON.parse(grid.dataset.isotope).filteredItems.length) {
  	$('#noItems').show();
	}
});

$(".click-specifications").click(function(evt){
  evt.preventDefault();
  let $parent = $(this).closest('.product-specifications');
  $parent.find(".product-specifications-text").toggle(500);
	$(this).find(".fa-angle-up").toggle();
	$(this).find(".fa-angle-down").toggle();
});


/**
 * Website javascript activeren
 */
$(document).ready(website.init);
