import * as dompack from 'dompack';
import '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';

import './web/css/bootstrap.min.css';
import './web/css/font-awesome.min.css';
import './web/css/owl.carousel.css';
import './web/css/website-20170941.css';
import './web/css/media-20170935.css';

// import './web/js/jquery.min.js';
import './web/js/bootstrap.min.es';
import './web/js/owl.carousel.js';
import './web/js/jquery.mb.ytplayer.js';
import './web/js/jquery.fitvids.js';
import './web/js/website-20170936';
import './web/js/jssor.slider-25.2.1.min.js';
import './web/js/sweetalert.min.js';
import './web/js/html2canvas.js';

import './shared/forms/forms';
import './shared/rtd/rtd';

import './blast.scss';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
openLinksInNewWindow();

import * as googleRecaptcha from "@mod-publisher/js/captcha/google-recaptcha";
import * as dialogapi from 'dompack/api/dialog';
import * as dialog from 'dompack/components/dialog';
googleRecaptcha.setupGoogleRecaptcha();
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

// components
import './embeddedobjects/embeddedobjects';
import './footer/footer';
import './header/header';

// pages
import './pages/contact/contact';
import './pages/home/home';
import './pages/search/search';

import $ from 'jquery';

let touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
if (!touchsupport){ // browser doesn't support touch
  document.documentElement.className += ' non-touch';
}

dompack.onDomReady(() => {
  for (const image of dompack.qSA('.emb-fullwidthimage__image')) {
    image.classList.add('img-fullwidth');
    image.classList.add('img-responsive');
  }

  // move video modals to the body container to fix z-index issues
  $('.video-modal').each(function() {
    dompack.qS('body').appendChild($(this).get(0));
  });

  // // flag empty paragraphs
  // $('p.normal').each(function() {
  //   if ($(this).text() === '')
  //     $(this).addClass('empty');
  // });
});
